const env = () => { // Gets the env dynamically based on the url
    var APP_ENV = '';
    switch (window.location.origin) {
        case "http://localhost:5000":
            APP_ENV = "development";
            break;
        case "https://dev-screener-fe.np.globalaes-aws.systems":
            APP_ENV = "development";
            break;
        case "https://pp-screener-fe.np.globalaes-aws.systems":
            APP_ENV = "qa";
            break;
        case "https://uat-screener-fe.np.globalaes-aws.systems":
            APP_ENV = "staging";
            break;
        case "https://screener.globalaes-aws.systems":
        case "https://screener.acurianhealth.com":
        case "https://screener.acurianhealth.com":
        case "https://studies.synexusclinic.co.uk":
        case "https://badania.przychodniasynexus.pl":
        case "https://studies.acurianhealth.com":
        case "https://www.studies.synexusclinic.co.uk":
        case "https://www.badania.przychodniasynexus.pl":
        case "https://www.studies.acurianhealth.com":
        case "https://www.screener.ppd.com":
        case "https://screener.ppd.com":
        case "http://screener.ppd.com":
            APP_ENV = "production";
            break;
        default:
            APP_ENV = "test";
            break;
    }
    return APP_ENV;
}

const loadJS = (file) => {
    var jsElm = document.createElement("script");
    jsElm.type = "application/javascript";
    jsElm.src = file;
    document.getElementsByTagName('head')[0].appendChild(jsElm);
}
switch (env()) {
    case "development":
        loadJS('/newrelic/new-relic-linked-to-dev-backend.js')
        break;
    case "qa":
        loadJS('/newrelic/new-relic-linked-to-pp-qa-backend.js');
        break;
    case "staging":
        loadJS('/newrelic/new-relic-linked-to-uat-stg-backend.js');
        break;
    case "production":
        loadJS('/newrelic/new-relic-linked-to-prod-backend.js');
        break;
    default: //development
        loadJS('/newrelic/new-relic-linked-to-dev-backend.js')
        break;
}
